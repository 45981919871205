.evaluation {
    width: 100%;

    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: center;

    background-color: white;

    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;

    border-radius: 0.5rem;
    border: 1px solid var(--border-gray);
    background: #FFF;
}

.evaluation > h3 {
    padding-top: 1.75rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.evaluation-body {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    padding: 1.75rem;

    gap: 3rem;
}

.evaluation-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;

    width: 100%;
}

.evaluation-section h2 {
    
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 0.5rem;

}

.evaluation-section-body {
    width: 100%;
}

.evaluation-notes {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.note {
    margin-bottom: 0.7rem;
}

/* Score Styling */
.score-Excellent {
    color: #056CF2;
    border: 1px solid var(--brand-blue-2, #056CF2);
    background: rgba(5, 108, 242, 0.10);

    height: 4.375rem;
    border-radius: 50rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.score-Good {
    color: #8DAC0D;
    border: 1px solid #C9F21D;
    background: rgba(201, 242, 29, 0.25);

    height: 4.375rem;
    border-radius: 50rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.score-Fair {
    color: #F26911;
    border: 1px solid #F26911;
    background: rgba(242, 105, 17, 0.10);
    
    height: 4.375rem;
    border-radius: 50rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.score-Lacking {
    color: #F44336; 
    border: 1px solid #f22b11;
    background: rgba(242, 43, 17, 0.1);

    height: 4.375rem;
    border-radius: 50rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
}

.score-Excellent p, .score-Good p, .score-Fair p, .score-Lacking p {
    width: 4.375rem;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}