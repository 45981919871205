.product-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.38rem;
    padding: 0.88rem 0.75rem;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;   
}

.product-record:hover {
    background-color: #EEE;
}

.product-record-left-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
}

.product-record-left-content h4 {
    margin-top: 0px;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}

.product-record-left-content p {
    margin-bottom: 0px;
    color: #666;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.product-record-right-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-record-right-content p {
    margin: 0px;
    padding: 0.5em;
    border-radius: 8px;
    text-align: center;
}