/* Default Mobile styles */

.login {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    background-color: var(--background-gray);
}

.login-container {
    /* width: 90%; */ /* full width on mobile for better UX */
    width: 90%;
    max-width: 30rem;
    margin: 1rem auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

.login-hero {
    padding: 3rem 1rem; /* reduced padding on mobile */
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background: linear-gradient(180deg, #056CF2 0%, rgba(5, 108, 242, 0.60) 100%);
    text-align: center; /* added this for center alignment */
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    gap: 0.5rem;
}

.login-form-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
}

.login-form-header-text {
    margin: 0px;
    text-align: center;
}

.login-form-fields {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
}

.login-form input {
    width: 100%; 
    padding: 0.5rem;
    border: 1px solid #EEE;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-form-buttons {
    display: flex;
    flex-direction: row; 
    width: 100%;
    margin-top: 1rem;
    gap: 0.75rem;
}

.login-form-buttons > * {
    width: 100%; 
    margin-bottom: 0.5rem; 
}

.submit-button, .forgot-password-button {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.8rem;
}

.submit-button {
    font-size: 1.2rem;
    color: #fff;
    background-color: #3a5e9c;
}

.submit-button:hover {
    background-color: #1e3666;
}

.forgot-password-button {
    background-color: #fff;
    transition: color 0.3s;
}

.no-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.no-account p {
    font-size: small;
}

.no-account a {
    font-weight: bold;
    transition: color 0.3s;
}

.no-account a:hover {
    color: #3a5e9c;
}

.register-name-info {
    display: flex;
    flex-direction: column; 
    width: 100%;
    gap: 0.5rem;
}

.register-name-info input {
    width: 100%; 
}

/* Desktop styles */
@media screen and (min-width: 810px) {
    .login-container {
        /* width: 70%; */
        width: 100%;
        max-width: 30rem;
        margin: 2rem auto;
    }

    .login-hero {
        padding: 6rem;
    }

    .login-form-buttons {
        flex-direction: row;
        justify-content: space-between;
    }

    .login-form-buttons > * {
        width: 48%; 
    }

    .register-name-info {
        flex-direction: row;
        justify-content: space-between;
    }
}
