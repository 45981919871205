.call-record-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 20px 0px;
    border-radius: 5px;
  
    /* Position the modal at the center of the screen */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    /* Set the z-index above everything else */
    z-index: 9999;
  
    /* Set the width and height */
    width: 80%;
    max-height: 90%;
  
    /* Add some styles for better visual appearance */
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.body {
    overflow: auto;
}

.header-wrapper .close-button {
    position: relative;
    background-color: transparent;
    top: 10px;
    left: 0px;
    margin-bottom: 1rem;
    border: none;
    font-size: 1.2rem;
    color: black;
    font-weight: bold;
    cursor: pointer;
  }