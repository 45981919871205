.password-reset-page {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.password-reset {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f3f6f9;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
}

.password-reset-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-reset-body {
    display: flex;
    flex-direction: column;
}

.password-reset-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.password-reset-account-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    width: 100%;
}

.password-reset h2 {
    color: #3a5e9c;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.password-reset input {
    padding: 0.5rem;
    margin: 0.2rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 95%;
}

.password-reset button {
    padding: 0.5rem;
    font-size: 1.2rem;
    color: #fff;
    background-color: #3a5e9c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.password-reset-form button:hover {
    background-color: #1e3666;
}
