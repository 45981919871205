.recording-replay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.recording-replay-body {
    gap: 0;
}

.recording-replay-body > .left { /* Left */
    background-color: white;

    height: 100vh;
    display: flex;
    align-items: center;
    
    padding: 10%;

}
.recording-replay-body > .right { /* Right */
    background-color: var(--background-gray);

    height: 100vh;
    display: flex;
    align-items: center;

    padding: 10%;
}

/* TODO: Refactor and combine with .call-simulation-logo */
.recording-replay-logo {
    position: absolute;
    padding: 1.25rem;
}
.recording-replay-logo h3 {

    font-family: 'Poppins', 'Salesforce Sans', Arial, sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0;
}