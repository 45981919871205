.users {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1.63rem;
}

.users-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.users-header h1 {
    margin: 0;
    color: #333;

    font-size: 1.75rem;
}

.new-buttons {
    display: flex;
    gap: 0.5rem;
}

.users-body {
    display: flex;
    flex-direction: column;
}

.users-table-header {
    display: flex;
    justify-content: space-between;
    padding: 0 2.5rem 0 1.5rem;
    color: #666666;
    font-weight: bold;
    font-size: 0.75rem;
}

.users-name-header {
    flex: 1;
    padding: 8px 0;
}

.users-role-header {
    flex: 0 0 60px;
    text-align: right;
    padding: 8px 0;
}

.users-table-rows {
    background-color: white;
    gap: 0.5rem;
    padding: 0.88rem 0.75rem;
    
    border-radius: 0.38rem;
    border: 1px solid #EEE;
}

.users-modals {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
