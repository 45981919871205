.scenario-call-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.88rem 0.75rem;
    border-radius: 0.375rem;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.scenario-call-record:hover {
    background-color: #EEE;
}

.scenario-call-record-left-content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem
}

.scenario-call-record-left-content p {
    color: #666;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.scenario-call-record-right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 150px;
}

.scenario-call-record-right-content p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 0.5rem;
    margin-left: 2rem;
}