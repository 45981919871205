.user-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: first baseline;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.3s;
  position: relative;
  width: 100%;

  box-sizing: border-box;
}

.user-dropdown-container:hover,
.user-dropdown-container.open {
  background-color: #fff;
}

.user-dropdown-container.open {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;

  font-size: 0.875rem;
}

.user-info {
  display: flex;
  flex-direction: column;
  /* gap: 0.25rem; */
}
.user-info .main {
  font-weight: bold;
}
.user-info .sub{
  color: #666;
}

.user-dropdown-menu {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  z-index: 1;
  /* width: calc(100% - 20px); */ /* Adjusted to match container width */
  width: 100%;
  margin-top: 1.25rem;
}

.menu-header {
  color: #666;
  text-align: left;

  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.menu-header > p {
  margin-bottom: 0.5rem;
}

.menu-item {
  color: #222;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 0.875rem;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-item a {
  color: #222;
  text-decoration: none;
}

.org-item {
  font-weight: normal;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 5px;
  margin: 0.25rem 0 0 0;
  transition: background-color 0.3s ease;

  box-sizing: border-box;
  text-transform: none;
  font-size: 0.875rem;
  color: #222;
}

.org-item:hover {
  background-color: #e0e0e0;
}

.org-item.active {
  width: 100%;
  background-color: #EEE;
}

