.dropdown-button {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;

  font-size: 0.875rem;
}

.dropdown-button:hover {
  background-color: #fff;
}

.dropdown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-icon-container {
  width: 24px;  
  height: 24px;
  border-radius: 4px;
  margin-right: 0.8em;
}

.dropdown-menu {
  display: none; /* Initially hidden */
  flex-direction: column;
  position: static; /* Changed from none to static, as none is not a valid value for position */
  align-items: flex-start;
  width: 100%;
}

.dropdown-button.active .dropdown-menu {
  display: flex; /* Display when active */
}

.dropdown-button .dropdown-button.active {
  background-color: (--theme-primary, #056CF2);
}
