.scenarios {
    /* padding: 20px;
    border-radius: 8px; 
    width: 75%; */

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.63rem;
}

.scenarios-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 15px 20px; */
    /* font-size: 1rem; */
}

.scenarios-header h1 {
    margin: 0;
    color: #333;
    
    font-size: 1.75rem;
}

.new-buttons {
    display: flex;
    gap: 0.5rem;
}

.scenarios-body {
    display: flex;
    flex-direction: column;
    
    /* gap: 0.5rem; */
    /* background-color: #fff;
    padding: 10px 20px; */
}

.scenario-table-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.75rem 0 1.5rem;
    color: #666666;
    font-weight: bold;
    font-size: 0.75rem;
  }
  
  .scenario-name-header {
    flex: 1;
    padding: 8px 0;
  }
  
  .scenario-active-header {
    flex: 0 0 60px;
    text-align: right;
    padding: 8px 0;
  }

.scenario-table-rows {
    background-color: white;
    gap: 0.5rem;
    padding: 0.88rem 0.75rem;
    
    border-radius: 0.38rem;
    border: 1px solid #EEE;
}

.scenarios-modals {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}
