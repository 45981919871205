.button-primary {
    display: flex;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    background: #000;
    color: white; 
    border: none; 
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button-primary:hover {
    background-color: var(--theme-primary, #001);
  }
  
  .button-primary:focus {
    outline: none;
    box-shadow: 0 0 0 1px rgba(255,255,255, 0.5), 0 0 0 2px var(--theme-primary, #000);
  }
  