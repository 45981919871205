.scenario-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Text/Body */
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    
  }

.header-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.12rem;
    align-items: start;

    position: sticky;
    top: 0px;

    background-color: white;
    border-bottom: 1px solid #EEE;

    width: 100%;
 }

 .header-wrapper h1 {
  margin: 0;
 }

 .header-wrapper p{
  color: #666;
 }
  
  .scenario-header-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .scenario-body-container {
    width: 100%;
    background-color: #F9F9F9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 1.5rem;
  }

  .scenario-body {
    width: 100%;
  }
  
  .scenario-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.62rem;
    gap: 1.25rem;
  }

  .scenario-info-header h2 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.75rem;
  }
  
  .prospect-info,
  .product-info {
    flex-basis: 50%;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #EEE;
    padding: 1.25rem;
  }
  
  .call-records {
    width: 100%;
    /* max-height: 400px; */ 
  }

  .call-records-body-wrapper {
    width: 100%;
  }

  .call-records-body-table-header {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    color: #666666;
    font-weight: bold;

    margin: 1.5rem 0rem 0.97rem 0rem;
  }

  .call-records-body-table-header span {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .call-records-body-table-header-right span {
    margin-left: 2rem;
    text-align: center;
  }

  .call-records-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #EEE;

    padding: 0.5rem 0.35rem;
  }
  
  .info-label {
    font-weight: bold;
  }

  .styled-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
    text-align: left;
  }

  .styled-table tbody tr {
    cursor: pointer;
  }
  
  .styled-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .styled-table tbody tr:hover {
    background-color: #92b9fb;/*#f5f5f5;*/
  }
  

  
  
  