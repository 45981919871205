/* App.css */
/* @import url('https://fonts.googleapis.com/css?family=Poppins'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700;900&family=Poppins:wght@400;500;600;700&display=swap');

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes up at least the full viewport height */
}

/* Add this to ensure your SideBar takes up the full height of the container */
.side-bar {
  min-height: 100%;
}

/* Add this to ensure your MainPanel takes up the full height of the container */
.main-panel {
  flex-grow: 1; /* Allows the MainPanel to grow and fill the remaining space */
  min-height: 100%;
}

/* Default styles for body */
body {
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;

  color: var(--text-default);
}

h1, h2, h3, h4, p {
  margin: 0;
}