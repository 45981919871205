.sidebar-button {
  display: flex;
  width: 100%;
  padding: 0.65rem;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background 0.3s;

  box-sizing: border-box;

  font-size: 0.875rem;
}

.sidebar-button:hover {
  background-color: #fff;  /* A subtle highlight on hover */
}

.sidebar-button.active {
  background-color: #EEE;
  /* background-color: #fff;
  color: #056CF2; 
  border: 2px solid #056CF2;  */
}

.sidebar-icon-container {
  /* width: 24px; 
  height: 24px;
  border-radius: 4px; */
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}
