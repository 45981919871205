.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 1rem;
}

.loading-spinner {
    border: 4px solid #eee;
    border-top: 4px solid #2196f3;
    border-radius: 50%;
    margin-top: 10px;
    margin-bottom: 8px;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
