

.call-simulation {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
}

.call-simulation-logo {
  position: absolute;
  padding: 1.25rem;
  top: 0;
  left: 0;
}
.call-simulation h3 {
  font-family: 'Poppins', 'Salesforce Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;
}

.call-simulation-body {
  gap: 0;
}

.call-simulation-body > .left { /* Left */
  background-color: white;

  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  padding: 10%;

}
.call-simulation-body > .right { /* Right */
  background-color: var(--background-gray);

  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10%;
}

.right-warning {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.call-simulation-info {
  width: 100%;
}

.agent-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  gap: 0.25rem;

  height: 70%;
  width: 100%;
}

.agent-image-wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
}
.agent-image {
  width: 9.375rem;
  height: 9.375rem;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.agent-profile-header {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.25rem;
}

.agent-profile-subheader {
  color: var(--text-sub);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.agent-subtitles {
  background-color: white;
  text-align: center;
  padding: 1.12rem;
  border: 1px solid var(--border-gray);
  border-radius: 0.375rem;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
  
  margin-top: 1.75rem;
  
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.timer {
  font-size: 24px;
  color: #222;
  font-weight: bold;
  margin-top: 14px;
  margin-bottom: 14px;
}

.call-button-controls {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 0.75rem;

  margin-top: 2.75rem;
}

.agent-expanding-circle.active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(221, 221, 221, 40%); 
  animation: expandingCircle 4s linear infinite;
}



@keyframes expandingCircle {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

/* loading spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  border: 4px solid #eee;
  border-top: 4px solid #2196f3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
  