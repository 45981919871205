.loading-results {

    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.loading-results-subheading {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.loading-spinner {
    border: 4px solid #000; /* Light grey */
    border-top: 4px solid #eee; /* Black */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;
    margin-right: 16px; /* Adds some space between the spinner and the text */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
