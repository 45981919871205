.scenario-record {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.38rem;
    padding: 0.88rem 0.75rem;
    background-color: transparent; 
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    box-sizing: border-box;
}

.scenario-record:hover {
    background-color: #eee;
}

.left-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-right: 1rem;
}

.scenario-name {
    font-size: 0.875rem;
    font-weight: bold;
}

.right-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Spacing between calls and toggle switch */
}

.calls {
    font-size: 1rem;
}

/* Toggle Switch styles */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 22px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 2.5px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(15px); /* Move to the right */

}
