@media screen and (min-width: 810px) {
    .login {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
    }
    
    .login-container {
        /* width: 30%;  */
        width: 100%;
        max-width: 30rem;
        margin: 2rem auto;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
    }
    
    .login-hero {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6rem;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background: linear-gradient(180deg, #056CF2 0%, rgba(5, 108, 242, 0.60) 100%)
    }
    
    .login-form {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }
    
    .login-form-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        margin-bottom: .75rem;
    }
    
    .login-form-header-text {
        margin: 0px;
        text-align: center;
    }
    
    .login-form-fields {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 0.62rem;
    }

    .login-form input {
        padding: 0.5rem;
        border: 1px solid #EEE;
        border-radius: 5px;
    }
    
    .login-form-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        gap: 0.75rem;
    }
    
    .login-form-buttons > * {
        flex: 1;
        width: 48%; 
    }
    
    .submit-button {
        padding: 0.5rem;
        font-size: 1.2rem;
        color: #fff;
        background-color: #3a5e9c;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
    }
    
    .submit-button:hover {
        background-color: #1e3666;
    }
    
    .forgot-password-button {
        padding: 0.5rem;
        margin-top: 0.5rem;
        background-color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;
        transition: color 0.3s;
    }
    
    .no-account {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .no-account p {
        font-size: small;
    }
    
    .no-account a {
        font-weight: bold;
        transition: color 0.3s;
    }
    
    .no-account a:hover {
        color: #056CF2;
    }
    
    .register-name-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 0.5rem;
    }

}