.voice-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;

    padding: 0rem 2rem 2rem 2rem;
    gap: 0.5rem;
}

.voice-step-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #eee;
    cursor: pointer;
    padding: 0.62rem 0.88rem;
}

.voice-step-card.selected {
    border: 1px solid #056CF2;
}

.details-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    padding: 0rem 2rem 2rem 2rem;
}

.detail-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
}

.detail-input-container input {
    padding: 8px;
    border: 1px solid #eee;
    border-radius: 6px;
    width: 90%;
    font-size: 1rem;
}

.detail-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 6px;
    margin: 0.5rem;
}

.detail-item p {
    margin-left: 1rem;
}