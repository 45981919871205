/* Default styles for Mobile */
.home {
    display: flex;
    flex-direction: column;

    font-family: 'Poppins', 'Inter', sans-serif;
}

.homenav {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000; /* To ensure it remains on top of other elements */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
    background: #fff;
    
    width: 100%;
    padding: 0.84rem 1.35rem;
}

.homenav.shadow {
    box-shadow: 0px 2px 4px 0px #0000000D;
}

.homenav-logo {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

}
.homenav-logo img {
    width: 1.72769rem;
    height: 1.125rem;
}
.homenav-logo h2 {
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.homenav-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 0.75rem;

}
.homenav-buttons a {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.homenav-buttons .button-primary {
    font-size: 0.875rem;
}

.home-body {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.75rem;

    padding-top: 3.75rem;
}

.home-slogan {

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    width: 100%;
}

.max-width {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.home-slogan h1 {
    text-align: center;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-slogan h2 {
    text-align: center;
    font-family: Inter;
    font-style: normal;
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

.highlighted {
    color: #1B6ADB;
    font-weight: 500;
}

.non-highlighted {
    color: #222222;
    font-weight: 500;
}

.subtext {
    color: #666666;
}

.home-hero,
.home-hero img {
    width: 100%;
}
.home-hero.banner video {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    border-radius: 0.5rem;
    border: 1px solid var(--Divider, #DDD);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.home-hero-banner-bg {
    border-radius: 0rem 1.5rem;
}
.home-hero-productshot {
    border: 1px solid #056CF9;
    border-radius: 6px;
}

.home-customers {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.125rem
}
.home-customers h3 {
    color: var(--Text-Sub, #666);
    font-family: Inter;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: normal;
}
.home-customers img {
    width: 9.74288rem;
    height: 2rem;
}

.home-divider {
    background-color: #DDD;
    height: 1px;
    width: 100%;
}

.home-subslogan {
    display: flex;
    flex-direction: column;
    gap: 0.5rem
}

.home-subslogan h3 {
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: #222;
}

.home-subslogan h4 {
    text-align: center;
    font-size: 0.875rem;
    font-weight: 400;
    color: #222;
}

.home-video-option-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    justify-content: center;

    font-size: 0.875rem;
    font-weight: 600;

    margin-top: 0.75rem;
    margin-bottom: 2.5rem;

    cursor: pointer;
}

.home-video-option-container img {
    width: 1.125rem;
    height: 1.125rem;
}

.home-video {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.home-video iframe {
    max-width: 100%;
    z-index: 9999;
    /* Treating it as a modal  */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem;
}

.features-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    align-items: center;
    justify-content: space-evenly;
    
    background: #F6F5F4;
    
    padding: 1.25rem;
    border-radius: 12px;
}

.features-card-icon {
    width: 2.25rem;
    height: 2.25rem;
}

.features-card-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: 0.625rem;
}

.features-card-upper h3 {
    text-align: center;
    /* Narrow/H2 */
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.features-card-upper p {
    /* Narrow/Body */
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.features-card-lower {
    display: flex;
}

.features-card-lower img {
    width: 100%;
    max-width: 18rem;
}

.home-built-for {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.125rem;

    border-top: 1px solid var(--Text-Default, #222);
    box-sizing: border-box;
    padding: 2rem 0rem;
}

.home-built-for h3 {
    font-size: 1rem;
    font-weight: 700;
}

.home-built-for-benefits {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.75rem;
}

.home-built-for-benefits-item {
    border-radius: 4px;
    border: 1px solid var(--Text-Default, #222);
    background: var(--Background, #FFF);
    width: 100%;
    padding: 1.25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-size: 0.875rem;
}

.home-built-for-professionals {
    background: rgba(201, 242, 29, 0.25);
}

.home-built-for-educators {
    background: rgba(5, 108, 242, 0.10);
    border-bottom: 1px solid #222;
}

.home-cta {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    font-size: 0.875rem;
    color: #000;
    
    width: 100%;
    padding: 0rem 2.25rem;
    box-sizing: border-box;
}

.home-cta img {
    width: 4.04875rem;
    height: 2.625rem;
}

.home-cta-header h3 {
    text-align: center;
    /* Narrow/H2 */
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-cta-button-area {
    display: flex;
    justify-content: center;
}

.home-cta-button-area button {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.75rem 1.25rem;
    border-radius: 50px;
    text-align: center;
}

.homefooter {
    background: #fff;
    color: #000;
    padding: 1.75rem 1.12rem;
    
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid var(--Footer-Border, #222);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.homefooter-section h3 {
    margin-top: 0;
    margin-bottom: 0.38rem;
}

.homefooter-copyright {
    color: #666;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* Tablet styles */
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .homenav {
        padding: 1.5rem 2.25rem;
    }

    .homenav-logo {
        gap: 0.625rem
    }
    .homenav-logo img {
        width: 2.6875rem;
        height: 1.75rem;
    }
    .homenav-logo h2{
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .homenav-buttons {
        position: absolute;
        right: 1.75rem;
    }

    .homenav-buttons a {
        padding: 0.62rem 1.25rem;

        font-size: 0.875rem;
    }

    .homenav-buttons .button-primary {
        font-size: 0.875rem;
    }

    
    .home-body {
        gap: 7.5rem;

        padding-top: 3.75rem;
    }

    .max-width {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
    
    .home-hero-banner-logo {
        position: absolute;
        height: 4rem;
        object-fit: contain;
    }
    .home-hero-banner-bg {
        border-radius: 0rem 3rem;
    }

    .home-slogan {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        font-size: 1.4rem;
    }

    .home-slogan h1 {
        text-align: center;
        /* Wide, Middle/H1 */
        font-family: Poppins;
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .home-slogan h2 {
        text-align: center;
        font-family: Inter;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .home-hero {
        margin: 0;
    }

    .home-subslogan {
        display: flex;
        flex-direction: column;
        gap: 0.75rem
    }

    .home-subslogan h3 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .home-subslogan h4 {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
    }
    
    .home-video-option-container {
        gap: 0.5rem;
        font-size: 1rem;

        margin-top: 1rem;
        margin-bottom: 2.62rem;
    }
    
    .home-video-option-container img {
        width: 2.25rem;
        height: 2.25rem;
    }

    .home-video iframe {
        width: 70rem;
        height: 25rem;
    }

    .home-customers {
        gap: 1.5rem;
    }
    .home-customers h3 {
        font-size: 1.125rem;
    }
    .home-customers img {
        width: 14.61425rem;
        height: 3rem;
    }

    .features-card {

    }
    
    .features-card-icon {
        width: 3.75rem;
        height: 3.75rem;
    }
    
    .features-card-upper {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        gap: 0.875rem;
    }
    
    .features-card-upper h3 {
        text-align: center;
        /* Wide, Middle/H2 */
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .features-card-upper p {
        text-align: center;
        /* Wide, Middle/Body */
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
    }
    
    .features-card-lower img {
        max-width: 33rem;
    }
    

    .home-built-for {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid var(--Text-Default, #222);
        width: 100%;
        box-sizing: border-box;
    }

    .home-built-for h3 {
        font-size: 1.5rem;
        font-weight: 700;
    }
    
    .home-built-for-benefits {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
        width: 100%;
    }
    
    .home-built-for-benefits-item {
        
        padding: 1.25rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .home-cta {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        
        padding: 0rem 2.25rem;
        box-sizing: border-box;
        width: 100%;
    }

    .home-cta img {
        width: 5.39831rem;
        height: 3.5rem;
    }
    
    .home-cta-header h3 {
        text-align: center;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .home-cta-button-area {
        display: flex;
        justify-content: center;
    }    
    
    .home-cta-button-area button {
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 1 1.5rem;
    }
    
    .homefooter {
        padding: 3.75rem;
    }

    .homefooter-section-logo {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        align-items: center;

        margin-bottom: 0.75rem;
    }

    .homefooter-section-logo img {
        width: 3.45538rem;
        height: 2.25rem;
    }
    
    .homefooter-section h3 {
        margin: 0;

        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .homefooter-copyright {
        color: #666;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

/* Laptop / Desktop styles */
/* @media screen and (min-width: 1024px) and (max-width: 1439px) { */
@media screen and (min-width: 1024px) {
    .home {
        display: flex;
        align-items: center;
    }
    
    .home-body {
        gap: 7.5rem;

        padding-top: 3.75rem;
    }

    .max-width {
        max-width: 68.75rem;

        padding-left: 3.125rem;
        padding-right: 3.125rem;
    }

    .homenav {
        font-size: 1.3rem;

        padding: 1.5rem 3rem;
    }
    .homenav-logo {

    }
    .homenav-logo img {
        width: 2.6875rem;
        height: 1.75rem;
    }
    .homenav-logo h2{
        font-family: Poppins;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .homenav-buttons {
        position: absolute;
        right: 1.5rem;
        margin-right: 1.5rem;
    }
    .homenav-buttons a {
        padding: 0.62rem 1.25rem;

        font-size: 1rem;
    }
    .homenav-buttons .button-primary {
        font-size: 1rem;
    }

    .home-hero-banner-bg {
        border-radius: 8px;
        border: 1px solid var(--Divider, #DDD);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    }

    .home-slogan {

        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        
    }
    
    .home-slogan h1 {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        margin-left: 18%;
        margin-right: 18%;
    }

    .home-slogan h2 {
        text-align: center;
        font-family: Inter;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .home-subslogan {
        display: flex;
        flex-direction: column;
        gap: 0.75rem
    }

    .home-subslogan h3{
        font-size: 1.5rem;
        font-weight: 700;
        line-height: normal;
    }
    .home-subslogan h4{
        font-size: 1rem;
        line-height: normal;
    }
    
    .home-hero {
        margin: 0;
    }
    .home-hero-productshot {
        border: 1.5px solid #056CF9;
        border-radius: 8px;
    }

    .home-video iframe {
        width: 80rem;
        height: 35rem;
    }

    .home-video-option-container {
        gap: 0.5rem;
        font-size: 1.25rem;

        margin-top: 1.25rem;
        margin-bottom: 6.25rem;
    }
    
    .home-video-option-container img {
        width: 2.25rem;
        height: 2.25rem;
    }

    .home-customers {
        gap: 1.5rem;
    }
    .home-customers h3 {
        font-size: 1.125rem;
    }
    .home-customers img {
        width: 14.61425rem;
        height: 3rem;
    }

    .features {
        display: grid;
        grid-template-columns: 1fr 1fr; 
        grid-gap: 2rem;
    }

    .features-card {
        /* flex: 1 1 calc(50% - 1rem); */
        padding: 1.75rem;
    }
    
    .features-card-icon {
        width: 2.625rem;
        height: 2.625rem;
    }
    
    .features-card-upper {
        display: flex;
        flex-direction: column;
        align-items: start;
        
        gap: 0.875rem;
    }
    
    .features-card-upper h3 {
        text-align: start;
        /* Wide, Middle/H2 */
        font-family: Poppins;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .features-card-upper p {
        text-align: start;
        /* Wide, Middle/Body */
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
    }
    
    .features-card-lower img {
        max-width: 100%;
    }

    .home-built-for {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid var(--Text-Default, #222);
        width: 100%;
        box-sizing: border-box;
    }

    .home-built-for h3 {
        font-size: 1.5rem;
        font-weight: 700;
    }
    
    .home-built-for-benefits {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
        width: 100%;

        max-width: 68.75rem;
    }
    
    .home-built-for-benefits-item {
        
        padding: 1.25rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .home-cta {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        
        padding: 0rem 2.25rem;
        box-sizing: border-box;
        width: 100%;
    }

    .home-cta img {
        width: 5.39831rem;
        height: 3.5rem;
    }
    
    .home-cta-header h3 {
        text-align: center;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .home-cta-button-area {
        display: flex;
        justify-content: center;
    }    
    
    .home-cta-button-area button {
        font-family: Poppins;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        padding: 1 1.5rem;
    }

    .homefooter {
        padding: 3.75rem;
    }

    .homefooter-section-logo {
        display: flex;
        flex-direction: row;
        gap: 0.75rem;
        align-items: center;

        margin-bottom: 0.75rem;
    }

    .homefooter-section-logo img {
        width: 3.45538rem;
        height: 2.25rem;
    }
    
    .homefooter-section h3 {
        margin: 0;

        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .homefooter-copyright {
        color: #666;
    
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
