.sidebar-latest {
    width: 20%; /* This ensures the sidebar takes up 1/5 of the container width */
    min-width: 16rem; /* Setting some bare-minimum size for desktop view, so sidebar options don't get squished out */
    max-width: 21rem;
    height: 100vh;
    background-color: #FFFFFF; 
    color: #000; 

    border-right: 1px solid #EEE;
  }
  
.sidebar-header {
  padding: 1.25rem;
}

  .sidebar-header h3 {
    font-family: 'Poppins', 'Salesforce Sans', Arial, sans-serif;
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0;
    
  }

  .sidebar-header img {
    max-width: 80%;
  }

.sidebar-user-wrapper {
  padding: 0rem 1.25rem 0rem 1.25rem;
}

.sidebar-divider {
  width: 100%;
  height: 1px;
  background-color: #EEE;
  margin: 1rem 0rem;
}

.sidebar-buttons {
  width: 100%;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  box-sizing: border-box;
}
  