.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the alpha value (last value) to change the darkness */
    z-index: 8888; /* Make sure the overlay is above other elements except the modal */
    cursor: pointer;
  }
  