.wizard-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
}

.wizard-header {
    width: 100%;
    padding: 2rem;
}
.wizard-header p {
    color: #666;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.wizard-footer {
    width: 100%;
    background-color: white;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.12rem 2rem;
}

.wizard-footer-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}