.prospect-step {
    padding: 0 2rem 2rem 2rem;
}

.prospect-step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 1rem;
    cursor: pointer;
}

.prospect-step-card.selected {
    border: 1px solid #056CF2;
}

.prospect-step-card-name {
    margin: 0.1rem;
    margin-top: 1rem;
    font-weight: bold;
    text-align: center;
}

.prospect-step-card-title {
    margin: 0.1rem;
    margin-bottom: 1rem;
    text-align: center;

    font-size: 0.875rem;
}

.product-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.5rem;
    padding: 0 2rem 2rem 2rem;
}

.product-step-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 0.25rem;
    padding: 0.62rem 0.88rem;
    cursor: pointer;
}

.product-step-card.selected {
    border: 1px solid #056CF2;
}

.product-step-card-left {
    margin-left: 0.1rem;
}

.product-step-card-name {
    font-weight: bold;
}

.product-step-card-description {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.info-step {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    gap: 1.5rem;
    padding: 0 2rem 2rem 2rem;
}

.info-step-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 0.62rem;
}

.info-step-field p {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

}

.info-step-field input {
    padding: 8px;
    border: 1px solid #eee;
    border-radius: 6px;
    width: 100%;
    font-size: 1rem;
}

.info-step-field textarea {
    padding: 8px;
    border: 1px solid #eee;
    border-radius: 6px;
    width: 100%;
    min-height: 4rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
}

.review-step {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    padding: 0rem 2rem 2rem 2rem;
    gap:1rem;
}

.review-step-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.review-step-section-title {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0.62rem;
}

.review-step-prospect-container,
.review-step-product-container,
.review-step-details-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid #eee;
    border-radius: 6px;    
    width: 100%;
    padding: 0.62rem 0.88rem;
}

.review-step-prospect-container-organizer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.review-step-details-container {
    flex-direction: column;
}
