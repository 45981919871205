.simulation-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
  }
  
  .results-container, .call-simulation-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .call-simulation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .return-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #3a5e9c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    align-self: center;
    margin-top: 20px; /* Adjust as needed */
  }
  