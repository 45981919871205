.scenario-info {
    /* margin: 1rem; */
    width: 100%;
}

.scenario-info-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    width: 100%;
    
}

.scenario-info-header > p {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    color: var(--text-sub);
    margin-bottom: 0.5rem;
}
.scenario-info-header > h1 {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.scenario-info-header > h2 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.scenario-info-prompt {
    color: var(--text-sub);

    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */

}

.scenario-info-block {
    border: 1px solid var(--border-gray);
    border-radius: var(--border-radius-default);
    padding: 1.5rem;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.scenario-info-block p {
    margin-bottom: 0.12rem;
}
