:root {
    --theme-primary: #056CF2;
    --theme-secondary: #ccc;
    --theme-primary-highlight: #476dad;

    --border-gray: #EEE;
    --border-radius-default: 0.5rem;

    --background-gray: #F9F9F9;

    --text-default: #222;
    --text-sub: #666;
  }
  