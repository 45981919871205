.dashboard {
    display: flex;
    background-color: #F9F9F9;
    flex-direction: row;
}

.dashboard > :nth-child(2) { /* Main panel */
    padding: 2.5rem;
}

.dashboard-loading {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}