.button-secondary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    gap: 10px;
    border-radius: 8px;
    background: #FFF;
    color: #222;
    border: 1px solid #EEE;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-secondary:hover {
    background: #EEE;
}

.button-secondary:focus {
    outline: none;
    box-shadow: 0 0 0 1px var(--theme-primary, #000), 0 0 0 2px var(--theme-secondary, #FFF);
}
  